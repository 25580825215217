#app{
  width: 100%;
}
.page{
  overflow-x: hidden;
}

.icon {
  flex-shrink: 0;
}

.icon-color-white {
  color: #ffffff;
}

.icon-color-grey {
  color: #F3F4F6;
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}